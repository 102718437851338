import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './Mint.css';
import api from '../api/redeemable_nuggets';
import {ADDRESS, ABI} from "../config1.js"
import {ADDRESS as ADDRESSTWO, ABI as ABITWO} from "../config3.js"
import {ADDRESS as ADDRESSTHREE, ABI as ABITHREE} from "../config2.js"
import {ADDRESS as ADDRESSFOUR, ABI as ABIFOUR} from "../config4.js"
import {ADDRESS as ADDRESSFIVE, ABI as ABIFIVE} from "../config5.js"
import {ADDRESS as ADDRESSSIX, ABI as ABISIX} from "../config6.js"
import {ADDRESS as ADDRESSSEVEN, ABI as ABISEVEN} from "../config7.js"






function Mint() {

    const navigate = useNavigate();
    const now = Date.now();

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)
    const [zombieSelected, setZombieSelected] = useState(false)
    const [humanSelected, setHumanSelected] = useState(false)
    const [nuggetSelected, setNuggetSelected] = useState(false)
    const [mediaSelected, setMediaSelected] = useState(false)
    const [stakingSelected, setStakingSelected] = useState(true)
    const [stakingSelected2, setStakingSelected2] = useState(false)
    const [claimingSelected, setClaimingSelected] = useState(false)

    const [bigRedeemTotal, setBigRedeemTotal] = useState(0)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_nuggets, set_how_many_nuggets] = useState(1)

    const [walkerContract, setWalkerContract] = useState(null)//
    const [nuggetContract, setNuggetContract] = useState(null)//
    const [stakingContract, setStakingContract] = useState(null)//
    const [nuggsContract, setNuggsContract] = useState(null)//
    const [mediaContract, setMediaContract] = useState(null)//
    const [mediaStakingContract, setMediaStakingContract] = useState(null)//
    const [mediaCheckingContract, setMediaCheckingContract] = useState(null)//

    // INFO FROM SMART Contract
    const [balance, setBalance] = useState(0)

    const [totalSupply, setTotalSupply] = useState(0)

    const [saleStarted, setSaleStarted] = useState(false)//

    const [getPrice, setNuggetPrice] = useState(0)//

    const [masterTokenList, setMasterTokenList] = useState([])

    const [hTokenList, setHTokenList] = useState([])

    const [zTokenList, setZTokenList] = useState([])

    const [nTokenList, setNTokenList] = useState([])

    const [mTokenList, setMTokenList] = useState([])

    const [owner, setOwner] = useState(false)

    const [tokenNumber, setTokenNumber] = useState(0)

    const [redeemableHumanState, setredeemableHumanState] = useState([]);
    const [redeemableZombieState, setredeemableZombieState] = useState([]);
    const [redeemableNuggetState, setredeemableNuggetState] = useState([]);
    const [redeemableMediaState, setredeemableMediaState] = useState([]);

    const [claimableHumanState, setclaimableHumanState] = useState([]);
    const [claimableZombieState, setclaimableZombieState] = useState([]);
    const [claimableNuggetState, setclaimableNuggetState] = useState([]);
    const [claimableMediaState, setclaimableMediaState] = useState([]);

    const [redeemable, setRedeemable] = useState([]);

    const [checkedZombies, setCheckedZombies] = useState([]);
    const [checkedZombiesKills, setCheckedZombiesKills] = useState([]);
    const [checkedZombiesSig, setCheckedZombiesSig] = useState([]);

    const [checkedHumans, setCheckedHumans] = useState([]);
    const [checkedHumansKills, setCheckedHumansKills] = useState([]);
    const [checkedHumansSig, setCheckedHumansSig] = useState([]);

    const [checkedNuggets, setCheckedNuggets] = useState([]);

    const [checkedMedia, setCheckedMedia] = useState([]);

    const [checkedZombiesClaim, setCheckedZombiesClaim] = useState([]);
    const [checkedZombiesClaimKills, setCheckedZombiesClaimKills] = useState([]);
    const [checkedZombiesClaimSig, setCheckedZombiesClaimSig] = useState([]);

    const [checkedHumansClaim, setCheckedHumansClaim] = useState([]);
    const [checkedHumansClaimKills, setCheckedHumansClaimKills] = useState([]);
    const [checkedHumansClaimSig, setCheckedHumansClaimSig] = useState([]);

    const [checkedNuggetsClaim, setCheckedNuggetsClaim] = useState([]);

    const [checkedMediaClaim, setCheckedMediaClaim] = useState([]);

    const [redeemable_nuggets, setRedeemable_nuggets] = useState([]);
    const [claimable_nuggets, setclaimable_nuggets] = useState([]);

    const [nuggets, setNuggets] = useState([]);

    const [clicked, setClicked] = useState(0)

    const [currentHuman, setCurrentHuman] = useState([]);
    const [currentZombie, setCurrentZombie] = useState([]);
    const [currentNugget, setCurrentNugget] = useState([]);
    const [currentMedia, setCurrentMedia] = useState([]);




    const tokenAddress = '0x39b037F154524333CbFCB8f193E08607B241A44C';
    const tokenSymbol = 'NUGGS';
    const tokenDecimals = 18;
    const tokenImage = 'https://assets.codepen.io/4625073/1.jpeg';

//    useEffect( async() => {
//
//        signIn()
//
//    }, [])
    useEffect(() => {
        zombiesOwned(walletAddress)
        nuggetsOwned(walletAddress)
        nuggsOwned(walletAddress)
        mediaOwned(walletAddress)
    }, [clicked]);
    useEffect(() => {
        setCheckedZombies(
            checkedZombies.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);
    useEffect(() => {
        setCheckedHumans(
            checkedHumans.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);
    useEffect(() => {
        setCheckedZombiesClaim(
            checkedZombiesClaim.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);
    useEffect(() => {
        setCheckedHumansClaim(
            checkedHumansClaim.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);

    useEffect(() => {



        setredeemableZombieState(
            redeemableZombieState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setredeemableHumanState(
            redeemableHumanState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setredeemableNuggetState(
            redeemableNuggetState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setredeemableNuggetState(
            redeemableNuggetState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setredeemableMediaState(
            redeemableMediaState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setclaimableZombieState(
            claimableZombieState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setclaimableHumanState(
            claimableHumanState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setclaimableNuggetState(
            claimableNuggetState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setclaimableNuggetState(
            claimableNuggetState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {



        setclaimableMediaState(
            claimableMediaState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);
        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            setSignedIn(true)
            callContractData(wallet)
            setClicked(clicked + 1)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const walkerContract = new window.web3.eth.Contract(ABI, ADDRESS)
        setWalkerContract(walkerContract)
        const stakingContract = new window.web3.eth.Contract(ABITWO, ADDRESSTWO)
        setStakingContract(stakingContract)
        const nuggetContract = new window.web3.eth.Contract(ABITHREE, ADDRESSTHREE)
        setNuggetContract(nuggetContract)
        const nuggsContract = new window.web3.eth.Contract(ABIFOUR, ADDRESSFOUR)
        setNuggsContract(nuggsContract)
        const mediaContract = new window.web3.eth.Contract(ABIFIVE, ADDRESSFIVE)
        setMediaContract(mediaContract)
        const mediaStakingContract = new window.web3.eth.Contract(ABISIX, ADDRESSSIX)
        setMediaStakingContract(mediaStakingContract)
        const mediaCheckingContract = new window.web3.eth.Contract(ABISEVEN, ADDRESSSEVEN)
        setMediaCheckingContract(mediaCheckingContract)



        const totalSupply = await walkerContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)



    }


    async function addTokenFunction() {
        if(signedIn){
            try {
              const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                  type: 'ERC20',
                  options: {
                    address: tokenAddress,
                    symbol: tokenSymbol,
                    decimals: tokenDecimals,
                    image: tokenImage,
                  },
                },
              });

              if (wasAdded) {
                console.log('Thanks for your interest!');
              } else {
                console.log('HelloWorld Coin has not been added');
              }
            } catch (error) {
              console.log(error);
            }
        }
    }


    async function nuggsOwned(walletAddress) {
        if(nuggsContract){
            const currentBalance = await nuggsContract.methods.balanceOf(walletAddress).call({from: walletAddress})
            setBalance(Math.round(currentBalance/1000000000000000000));
    }}

    async function zombiesOwned(walletAddress) {
        if (walkerContract) {
            const walkersOfOwner = await walkerContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})
            //console.log(walkersOfOwner)
            //console.log(walkersOfOwner.length)
            setCheckedZombies(checkedZombies => [])
            setCheckedHumans(checkedHumans => [])
            setCheckedZombiesClaim(checkedZombiesClaim => [])
            setCheckedHumansClaim(checkedHumansClaim => [])
            setredeemableZombieState(redeemableZombieState => [])
            setredeemableHumanState(redeemableHumanState => [])
            setclaimableZombieState(claimableZombieState => [])
            setclaimableHumanState(claimableHumanState => [])
            setZTokenList(zTokenList => [])

            let f = false;
            let k = 0;
            for (let i = 0; i < walkersOfOwner.length; i++) {
                //f = await walkerContract.methods.getnuggetsEarlyMintRedeemed(walkersOfOwner[i]).call()

                if (f == false) {
                    try {

                        const response = await api.get('/zombiestaking/'+walkersOfOwner[i]);
                        const alreadyGone = await stakingContract.methods.zombieDeepFryer(walkersOfOwner[i]).call({from: walletAddress})
                        const alreadyGone2 = await stakingContract.methods.humanDeepFryer(walkersOfOwner[i]).call({from: walletAddress})
                        setCurrentHuman(response.data.kills);
                        //console.log(alreadyGone);
                        //console.log(response.data);
                        if(alreadyGone.owner != 0x0000000000000000000000000000000000000000 && alreadyGone2.owner != 0x0000000000000000000000000000000000000000){
                            console.log(".")
                            const nuggsReady = (now()-response.data.value);
                            setclaimableZombieState(claimableZombieState => [ ...claimableZombieState, {
                                select: false,
                                id: walkersOfOwner[i],
                                nugget: "Claim " + response.data.Class + " " + walkersOfOwner[i],
                                number: walkersOfOwner[i],
                                kills: response.data.kills,
                                class: response.data.Class,
                                sigHash: response.data.signaturehash,
                                value: "NUGGS claimable: " + Math.floor(((now-(1000*alreadyGone.value))/21600000))*(25+response.data.kills)
                            }])

                            const response2 = await api.get('humanstaking/'+walkersOfOwner[i]);
                            setclaimableHumanState(claimableHumanState => [ ...claimableHumanState, {
                                select: false,
                                id: walkersOfOwner[i],
                                nugget: "Claim Human " + walkersOfOwner[i],
                                number: walkersOfOwner[i],
                                kills: response2.data.kills,
                                class: response2.data.Class,
                                sigHash: response2.data.signaturehash,
                                value: "NUGGS claimable: " + Math.floor(((now-(1000*alreadyGone2.value))/21600000))*(250)
                            }])
                            setBigRedeemTotal(bigRedeemTotal=> bigRedeemTotal + Math.floor(((now-(1000*alreadyGone.value))/21600000))*(25+response.data.kills) + Math.floor(((now-(1000*alreadyGone2.value))/21600000))*(250))

                        }else if(alreadyGone2.owner != 0x0000000000000000000000000000000000000000){
                            const response2 = await api.get('humanstaking/'+walkersOfOwner[i]);
                            setclaimableHumanState(claimableHumanState => [ ...claimableHumanState, {
                                select: false,
                                id: walkersOfOwner[i],
                                nugget: "Claim Human " + walkersOfOwner[i],
                                number: walkersOfOwner[i],
                                kills: response2.data.kills,
                                class: response2.data.Class,
                                sigHash: response2.data.signaturehash,
                                value: "NUGGS claimable: " + Math.floor(((now-(1000*alreadyGone2.value))/21600000))*(250)
                            }])
                            setBigRedeemTotal(bigRedeemTotal=> bigRedeemTotal + Math.floor(((now-(1000*alreadyGone2.value))/21600000))*(250))
                            console.log(".1")
                            console.log(response.data.kills)
                            if(typeof(response.data.kills) != "undefined"){
                                setredeemableZombieState(redeemableZombieState => [ ...redeemableZombieState, {
                                    select: false,
                                    id: walkersOfOwner[i],
                                    nugget: "Stake Revived Human " + walkersOfOwner[i],
                                    number: walkersOfOwner[i],
                                    kills: response.data.kills,
                                    class: response.data.Class,
                                    sigHash: response.data.signaturehash
                                }])
                            }

                        }
                        else if(alreadyGone.owner != 0x0000000000000000000000000000000000000000){
                            console.log(".2")
                            setclaimableZombieState(claimableZombieState => [ ...claimableZombieState, {
                                select: false,
                                id: walkersOfOwner[i],
                                nugget: "Claim " + response.data.Class + " " + walkersOfOwner[i],
                                number: walkersOfOwner[i],
                                kills: response.data.kills,
                                class: response.data.Class,
                                sigHash: response.data.signaturehash,
                                value: "NUGGS claimable: " + Math.floor(((now-(1000*alreadyGone.value))/21600000))*(25+response.data.kills)
                            }])
                            setBigRedeemTotal(bigRedeemTotal => bigRedeemTotal + Math.floor(((now-(1000*alreadyGone.value))/21600000))*(25+response.data.kills))

                            if(response.data.Class != 'Zombie'){
                                const response2 = await api.get('humanstaking/'+walkersOfOwner[i]);
                                setredeemableHumanState(redeemableHumanState => [ ...redeemableHumanState, {
                                    select: false,
                                    id: walkersOfOwner[i],
                                    nugget: "Stake Human " + walkersOfOwner[i],
                                    number: walkersOfOwner[i],
                                    kills: response2.data.kills,
                                    class: response2.data.Class,
                                    sigHash: response2.data.signaturehash
                                }])

                            }

                        }else if(response.data.Class == 'Zombie'){
                            setredeemableZombieState(redeemableZombieState => [ ...redeemableZombieState, {
                                select: false,
                                id: walkersOfOwner[i],
                                nugget: "Stake " + response.data.Class + " " + walkersOfOwner[i],
                                number: walkersOfOwner[i],
                                kills: response.data.kills,
                                class: response.data.Class,
                                sigHash: response.data.signaturehash
                            }])
                        }else if(response.data.kills != null){
                            setredeemableZombieState(redeemableZombieState => [ ...redeemableZombieState, {
                                select: false,
                                id: walkersOfOwner[i],
                                nugget: "Stake " + response.data.Class + " " + walkersOfOwner[i],
                                number: walkersOfOwner[i],
                                kills: response.data.kills,
                                class: response.data.Class,
                                sigHash: response.data.signaturehash
                            }])
                        }else if(response.data.kills != null){
                            const response2 = await api.get('humanstaking/'+walkersOfOwner[i]);
                            setredeemableHumanState(redeemableHumanState => [ ...redeemableHumanState, {
                                select: false,
                                id: walkersOfOwner[i],
                                nugget: "Stake Human " + walkersOfOwner[i],
                                number: walkersOfOwner[i],
                                kills: response2.data.kills,
                                class: response2.data.Class,
                                sigHash: response2.data.signaturehash
                            }])
                        }else {
                            const response = await api.get('humanstaking/'+walkersOfOwner[i]);
                            setredeemableHumanState(redeemableHumanState => [ ...redeemableHumanState, {
                                select: false,
                                id: walkersOfOwner[i],
                                nugget: "Stake Human " + walkersOfOwner[i],
                                number: walkersOfOwner[i],
                                kills: 0,
                                class: response.data.Class,
                                sigHash: response.data.signaturehash
                            }])
                        }

                    } catch (err) {

                    }


                    setZTokenList(zTokenList => [ ...zTokenList, {
                        select: false,
                        id: walkersOfOwner[i],
                        nugget: "Stake Human " + walkersOfOwner[i],
                        number: walkersOfOwner[i]

                    }])


                    k++;


                } else {

                }
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    async function nuggetsOwned(walletAddress) {
        if (nuggetContract) {
            const nuggetsOfOwner = await nuggetContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})
            //console.log(nuggetsOfOwner)
            //console.log(nuggetsOfOwner.length)
            setCheckedNuggets(checkedNuggets => [])
            setCheckedNuggetsClaim(checkedNuggetsClaim => [])


            setredeemableNuggetState(redeemableNuggetState => [])
            setclaimableNuggetState(claimableNuggetState => [])
            setNTokenList(nTokenList => [])
            let f = false;
            let k = 0;
            for (let i = 0; i < nuggetsOfOwner.length; i++) {
                //f = await walkerContract.methods.getnuggetsEarlyMintRedeemed(nuggetsOfOwner[i]).call()

                if (f == false) {
                    try {


                        const alreadyGone3 = await stakingContract.methods.nuggetDeepFryer(nuggetsOfOwner[i]).call({from: walletAddress})
                        if(alreadyGone3.owner != 0x0000000000000000000000000000000000000000){
                            console.log(".")
                            setclaimableNuggetState(claimableNuggetState => [ ...claimableNuggetState, {
                                select: false,
                                id: nuggetsOfOwner[i],
                                nugget: "Claim Nugget " + nuggetsOfOwner[i],
                                number: nuggetsOfOwner[i],
                                value: "NUGGS claimable: " + Math.floor(((now-(1000*alreadyGone3.value))/21600000)*(30))

                            }])
                        }else {
                            setredeemableNuggetState(redeemableNuggetState => [ ...redeemableNuggetState, {
                                select: false,
                                id: nuggetsOfOwner[i],
                                nugget: "Stake Nugget " + nuggetsOfOwner[i],
                                number: nuggetsOfOwner[i],

                            }])
                        }


                    } catch (err) {

                    }


                    setNTokenList(nTokenList => [ ...nTokenList, {
                        select: false,
                        id: nuggetsOfOwner[i],
                        nugget: "Stake Nugget " + nuggetsOfOwner[i],
                        number: nuggetsOfOwner[i]

                    }])


                    k++;


                } else {

                }
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    async function mediaOwned(walletAddress) {
        if (mediaContract) {
            const mediaOfOwner = await mediaCheckingContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})
            //console.log(nuggetsOfOwner)
            //console.log(nuggetsOfOwner.length)
            setCheckedMedia(checkedMedia => [])
            setCheckedMediaClaim(checkedMediaClaim => [])


            setredeemableMediaState(redeemableMediaState => [])
            setclaimableMediaState(claimableMediaState => [])
            setMTokenList(mTokenList => [])
            let f = false;
            let k = 0;
            for (let i = 0; i < mediaOfOwner.length; i++) {
                //f = await walkerContract.methods.getnuggetsEarlyMintRedeemed(nuggetsOfOwner[i]).call()

                if (f == false) {
                    try {


                        const alreadyGone4 = await mediaStakingContract.methods.mediaDeepFryer(mediaOfOwner[i]).call({from: walletAddress})
                        if(alreadyGone4.owner != 0x0000000000000000000000000000000000000000){
                            console.log(".")
                            setclaimableMediaState(claimableMediaState => [ ...claimableMediaState, {
                                select: false,
                                id: mediaOfOwner[i],
                                nugget: "Claim Media ID: " + mediaOfOwner[i],
                                number: mediaOfOwner[i],
                                value: "NUGGS claimable: " + Math.floor(((now-(1000*alreadyGone4.value))/21600000)*(25))

                            }])
                        }else {
                            setredeemableMediaState(redeemableMediaState => [ ...redeemableMediaState, {
                                select: false,
                                id: mediaOfOwner[i],
                                nugget: "Stake Media ID: " + mediaOfOwner[i],
                                number: mediaOfOwner[i],

                            }])
                        }


                    } catch (err) {

                    }


                    setMTokenList(mTokenList => [ ...mTokenList, {
                        select: false,
                        id: mediaOfOwner[i],
                        nugget: "Stake Media " + mediaOfOwner[i],
                        number: mediaOfOwner[i]

                    }])


                    k++;


                } else {

                }
            }

        } else {
            console.log("Wallet not connected")
        }
    };


    async function stakeZombies() {
        if (stakingContract) {


            const price = (0);

            const nugIds = checkedZombies.map((checkedZombies) => {
                return checkedZombies.id
            })
            const nugKills = checkedZombiesKills.map((checkedZombiesKills) => {
                return checkedZombiesKills.kills
            })
            const nugSig = checkedZombiesSig.map((checkedZombiesSig) => {
                return checkedZombiesSig.sigHash
            })
            //console.log(nugIds)
            //console.log(nugKills)
            //console.log(nugSig)

            const gasAmount = await stakingContract.methods.stakeZombies(nugIds, nugKills, nugSig).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            stakingContract.methods
                .stakeZombies(nugIds, nugKills, nugSig)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };

    async function stakeHumans() {
        if (stakingContract) {


            const price = (0);

            const nugIds = checkedHumans.map((checkedHumans) => {
                return checkedHumans.id
            })
            const nugKills = checkedHumansKills.map((checkedHumansKills) => {
                return checkedHumansKills.kills
            })
            const nugSig = checkedHumansSig.map((checkedHumansSig) => {
                return checkedHumansSig.sigHash
            })
            //console.log(nugIds)
            //console.log(nugKills)
            //console.log(nugSig)

            const gasAmount = await stakingContract.methods.stakeHumans(nugIds, nugKills, nugSig).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            stakingContract.methods
                .stakeHumans(nugIds, nugKills, nugSig)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };

    async function stakeNuggets() {
        if (stakingContract) {


            const price = (0);

            const nugIds = checkedNuggets.map((checkedNuggets) => {
                return checkedNuggets.id
            })

            //console.log(nugIds)


            const gasAmount = await stakingContract.methods.stakeNugget(nugIds).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            stakingContract.methods
                .stakeNugget(nugIds)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };
    async function stakeMedia() {
        if (mediaStakingContract) {


            const price = (0);

            const nugIds = checkedMedia.map((checkedMedia) => {
                return checkedMedia.id
            })

            //console.log(nugIds)


            const gasAmount = await mediaStakingContract.methods.stakeMedia(nugIds).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            mediaStakingContract.methods
                .stakeMedia(nugIds)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };
    async function claimZombies() {
        if (stakingContract) {


            const price = (0);

            const nugIds = checkedZombiesClaim.map((checkedZombiesClaim) => {
                return checkedZombiesClaim.id
            })
            const nugKills = checkedZombiesClaimKills.map((checkedZombiesClaimKills) => {
                return checkedZombiesClaimKills.kills
            })
            const nugSig = checkedZombiesClaimSig.map((checkedZombiesClaimSig) => {
                return checkedZombiesClaimSig.sigHash
            })
            const claiming = false;
            //console.log(nugIds)
            //console.log(nugKills)
            //console.log(nugSig)

            const gasAmount = await stakingContract.methods.claimZombieNUGGSRewards(nugIds, claiming).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            stakingContract.methods
                .claimZombieNUGGSRewards(nugIds, claiming)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };

    async function claimHumans() {
        if (stakingContract) {


            const price = (0);

            const nugIds = checkedHumansClaim.map((checkedHumansClaim) => {
                return checkedHumansClaim.id
            })
            const nugKills = checkedHumansClaimKills.map((checkedHumansClaimKills) => {
                return checkedHumansClaimKills.kills
            })
            const nugSig = checkedHumansClaimSig.map((checkedHumansClaimSig) => {
                return checkedHumansClaimSig.sigHash
            })
            const claiming = false;
            //console.log(nugIds)
            //console.log(nugKills)
            //console.log(nugSig)

            const gasAmount = await stakingContract.methods.claimHumanNUGGSRewards(nugIds, claiming).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            stakingContract.methods
                .claimHumanNUGGSRewards(nugIds, claiming)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };

    async function claimNuggets() {
        if (stakingContract) {


            const price = (0);

            const nugIds = checkedNuggetsClaim.map((checkedNuggetsClaim) => {
                return checkedNuggetsClaim.id
            })
            const claiming = false;

            //console.log(nugIds)


            const gasAmount = await stakingContract.methods.claimNerdyNuggetsNUGGSRewards(nugIds, claiming).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            stakingContract.methods
                .claimNerdyNuggetsNUGGSRewards(nugIds, claiming)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };
    async function claimMedia() {
        if (mediaStakingContract) {


            const price = (0);

            const nugIds = checkedMediaClaim.map((checkedMediaClaim) => {
                return checkedMediaClaim.id
            })
            const claiming = false;

            //console.log(nugIds)


            const gasAmount = await mediaStakingContract.methods.claimMediaNUGGSRewards(nugIds, claiming).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            mediaStakingContract.methods
                .claimMediaNUGGSRewards(nugIds, claiming)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };






const handleClick = () => {
    //console.log('Hello there!');
    stakeZombies()
};
const handleClickClaim = () => {
    //console.log('Hello there!');
    claimZombies()
};
const handleClick2 = () => {
    console.log('Hello there!');
    //nuggetsOwned(walletAddress)
};
const handleClick3 = () => {
    console.log('Hello there!');

};
const handleClick4 = () => {
    setZombieSelected(true);
    setHumanSelected(false);
    setNuggetSelected(false);

};
const handleClick5 = () => {
    setHumanSelected(true);
    setZombieSelected(false);
    setNuggetSelected(false);

};
const handleClick6 = () => {
    setHumanSelected(false);
    setZombieSelected(false);
    setNuggetSelected(true);

};

const handleClickN = () => {
    //console.log('Hello there!');
    stakeNuggets()
};
const handleClickNClaim = () => {
    //console.log('Hello there!');
    claimNuggets()
};

const handleClickM = () => {
    //console.log('Hello there!');
    stakeMedia()
};
const handleClickMClaim = () => {
    //console.log('Hello there!');
    claimMedia()
};

const handleClickH = () => {
    //console.log('Hello there!');
    stakeHumans()
};
const handleClickHClaim = () => {
    //console.log('Hello there!');
    claimHumans()
};
const handleClickClaiming = () => {
    //console.log('Hello there!');
    setStakingSelected(false)
    setStakingSelected2(false)
    setHumanSelected(true);
    setZombieSelected(true);
    setNuggetSelected(true);
    setMediaSelected(true);
};
const handleClickStaking = () => {
    //console.log('Hello there!');
    setStakingSelected(true)
    setStakingSelected2(true)
    setHumanSelected(true);
    setZombieSelected(true);
    setNuggetSelected(true);
    setMediaSelected(true);

};
const handleClickAddToken = () => {
    //console.log('Hello there!');
    addTokenFunction()
};


return (
    <>
    {stakingSelected ?
    <div className="daddy">
        <div className='new-body'>
            <div className='hero-container-Private'>



                <div id="mint" className="flex">



                </div>
                <div className='tabs'>
                    { signedIn?
                    <div className='balance-display'>
                    <h1>
                    Unclaimed Rewards: {bigRedeemTotal} NUGGS
                    </h1>
                    </div>
                    :
                    <div className="do-not-display">

                    </div>
                    }
                </div>
                <div className='tabs'>
                    { signedIn?
                    <div >
                    <Button
                        className='btns'
                        buttonStyle='btn--outline-round'
                        buttonSize='btn--large-2'
                        onClick={handleClickAddToken}
                    >
                    Add NUGGS token to meta mask
                    </Button>
                    </div>
                    :
                    <div className="do-not-display">

                    </div>
                    }
                </div>
                <div className='tabs'>
                    { signedIn?
                    <div>
                    {stakingSelected2?
                    <Button
                        className='btns'
                        buttonStyle='btn--outline2'
                        buttonSize='btn--large'
                        onClick={handleClick3}
                    >
                    Showing Staking
                    </Button>
                    : <Button
                        onClick={handleClickStaking}
                        className='btns'
                        buttonStyle='btn--outline2'
                        buttonSize='btn--large'
                    >Show Staking
                    </Button>
                    }
                    {stakingSelected?
                    <Button
                        className='btns'
                        buttonStyle='btn--outline2'
                        buttonSize='btn--large'
                        onClick={handleClickClaiming}
                    >
                    Show Claiming
                    </Button>
                    : <Button
                        onClick={handleClick3}
                        className='btns'
                        buttonStyle='btn--outline2'
                        buttonSize='btn--large'
                    >Showing Claiming
                    </Button>
                    }
                    </div>
                    :
                    <div className="do-not-display">

                    </div>
                    }
                </div>
                <div className='hero-btns'>
                {signedIn?
                <></>
                : <Button
                    onClick={signIn}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >Click to Connect Wallet with Metamask
                </Button>
                }
                </div>


            </div>


        </div>
        {zombieSelected == false && humanSelected == false && nuggetSelected == false ?
        <div className="FAQ-container">





        <h1>Welcome to the Deepfryer:</h1>
        <h2>Your home for ETH Walkers and Nerdy Nugget staking</h2>




        </div>
        :
        <div className='do-not-display'></div>
        }
        {stakingSelected2 ?
        <div className="FAQ-container">
        <h1>Staking</h1>
        <div className='grid-container-deepfryer'>
        <div className='list-card'>
        {zombieSelected ?
        <div>
        <div className='hero-btns'>
        {checkedZombies.length > 0?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClick}
        >
        Stake Selected Zombies (you pay GAS)
        </Button>
        : <Button
            onClick={handleClick3}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Select Zombies to Stake Below
        </Button>
        }
        </div>
        <p></p>
        <table className='table1'>

                    <tbody>
                        {
                            redeemableZombieState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        //console.log(redeemableZombieState);
                                        //console.log(checkedZombies);
                                        //console.log(checkedZombiesKills)
                                        //console.log(checkedZombiesSig)
                                        setredeemableZombieState(
                                            redeemableZombieState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newCheckedZombiesClaim = [ ...checkedZombies];
                                                        const index = checkedZombies.findIndex((checkedZombies)=> checkedZombies.id === data.id);
                                                        newCheckedZombiesClaim.splice(index, 1);
                                                        setCheckedZombiesClaim(newCheckedZombiesClaim);

                                                        const newCheckedZombiesKills = [ ...checkedZombiesKills];
                                                        const index2 = checkedZombiesKills.findIndex((checkedZombiesKills)=> checkedZombiesKills.kills === data.kills);
                                                        newCheckedZombiesKills.splice(index2, 1);
                                                        setCheckedZombiesKills(newCheckedZombiesKills);

                                                        const newCheckedZombiesSig = [ ...checkedZombiesSig];
                                                        const index3 = checkedZombiesSig.findIndex((checkedZombiesSig)=> checkedZombiesSig.sigHash === data.sigHash);
                                                        newCheckedZombiesSig.splice(index3, 1);
                                                        setCheckedZombiesSig(newCheckedZombiesSig);

                                                    }


                                                    data.select = checked;

                                                    setCheckedZombies(checkedZombies => [ ...checkedZombies, {
                                                        id: data.id,

                                                    }])
                                                    setCheckedZombiesKills(checkedZombiesKills => [ ...checkedZombiesKills, {
                                                        kills: data.kills,

                                                    }])
                                                    setCheckedZombiesSig(checkedZombiesSig => [ ...checkedZombiesSig, {
                                                        sigHash: data.sigHash,

                                                    }])

                                                    if (checked === false) {

                                                        const newCheckedZombies = [ ...checkedZombies];
                                                        const index = checkedZombies.findIndex((checkedZombies)=> checkedZombies.id === data.id);
                                                        newCheckedZombies.splice(index, 1);
                                                        setCheckedZombies(newCheckedZombies);

                                                        const newCheckedZombiesKills = [ ...checkedZombiesKills];
                                                        const index2 = checkedZombiesKills.findIndex((checkedZombiesKills)=> checkedZombiesKills.kills === data.kills);
                                                        newCheckedZombiesKills.splice(index2, 1);
                                                        setCheckedZombiesKills(newCheckedZombiesKills);

                                                        const newCheckedZombiesSig = [ ...checkedZombiesSig];
                                                        const index3 = checkedZombiesSig.findIndex((checkedZombiesSig)=> checkedZombiesSig.sigHash === data.sigHash);
                                                        newCheckedZombiesSig.splice(index3, 1);
                                                        setCheckedZombiesSig(newCheckedZombiesSig);

                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>




        </div>
        :
        <div className='do-not-display'></div>
        }
        </div>
        <div className='list-card'>
        {humanSelected ?
        <div>
        <div className='hero-btns'>
        {checkedHumans.length > 0?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClickH}
        >
        Stake Selected Humans (you pay GAS)
        </Button>
        : <Button
            onClick={handleClick3}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Select Humans to Stake Below
        </Button>
        }
        </div>
        <table className='table1'>

                    <tbody>
                        {
                            redeemableHumanState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        //console.log(redeemableHumanState);
                                        //console.log(checkedHumans);
                                        //console.log(checkedHumansKills)
                                        //console.log(checkedHumansSig)
                                        setredeemableHumanState(
                                            redeemableHumanState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newcheckedHumans = [ ...checkedHumans];
                                                        const index = checkedHumans.findIndex((checkedHumans)=> checkedHumans.id === data.id);
                                                        newcheckedHumans.splice(index, 1);
                                                        setCheckedHumans(newcheckedHumans);

                                                        const newcheckedHumansKills = [ ...checkedHumansKills];
                                                        const index2 = checkedHumansKills.findIndex((checkedHumansKills)=> checkedHumansKills.kills === data.kills);
                                                        newcheckedHumansKills.splice(index2, 1);
                                                        setCheckedHumansKills(newcheckedHumansKills);

                                                        const newcheckedHumansSig = [ ...checkedHumansSig];
                                                        const index3 = checkedHumansSig.findIndex((checkedHumansSig)=> checkedHumansSig.sigHash === data.sigHash);
                                                        newcheckedHumansSig.splice(index3, 1);
                                                        setCheckedHumansSig(newcheckedHumansSig);

                                                    }


                                                    data.select = checked;

                                                    setCheckedHumans(checkedHumans => [ ...checkedHumans, {
                                                        id: data.id,

                                                    }])
                                                    setCheckedHumansKills(checkedHumansKills => [ ...checkedHumansKills, {
                                                        kills: data.kills,

                                                    }])
                                                    setCheckedHumansSig(checkedHumansSig => [ ...checkedHumansSig, {
                                                        sigHash: data.sigHash,

                                                    }])

                                                    if (checked === false) {

                                                        const newcheckedHumans = [ ...checkedHumans];
                                                        const index = checkedHumans.findIndex((checkedHumans)=> checkedHumans.id === data.id);
                                                        newcheckedHumans.splice(index, 1);
                                                        setCheckedHumans(newcheckedHumans);

                                                        const newcheckedHumansKills = [ ...checkedHumansKills];
                                                        const index2 = checkedHumansKills.findIndex((checkedHumansKills)=> checkedHumansKills.kills === data.kills);
                                                        newcheckedHumansKills.splice(index2, 1);
                                                        setCheckedHumansKills(newcheckedHumansKills);

                                                        const newcheckedHumansSig = [ ...checkedHumansSig];
                                                        const index3 = checkedHumansSig.findIndex((checkedHumansSig)=> checkedHumansSig.sigHash === data.sigHash);
                                                        newcheckedHumansSig.splice(index3, 1);
                                                        setCheckedHumansSig(newcheckedHumansSig);

                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>






        </div>
        :
        <div className='do-not-display'></div>
        }
        </div>
        <div className='list-card'>
        {nuggetSelected ?
        <div>
        <div className='hero-btns'>
        {checkedNuggets.length > 0?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClickN}
        >
        Stake Selected Nerdy Nuggets (you pay GAS)
        </Button>
        : <Button
            onClick={handleClick3}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Select Nuggets to Stake Below
        </Button>
        }
        </div>
        <table className='table1'>

                    <tbody>
                        {
                            redeemableNuggetState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        //console.log(redeemableNuggetState);
                                        //console.log(checkedNuggets);

                                        setredeemableNuggetState(
                                            redeemableNuggetState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newcheckedNuggets = [ ...checkedNuggets];
                                                        const index = checkedNuggets.findIndex((checkedNuggets)=> checkedNuggets.id === data.id);
                                                        newcheckedNuggets.splice(index, 1);
                                                        setCheckedNuggets(newcheckedNuggets);



                                                    }


                                                    data.select = checked;

                                                    setCheckedNuggets(checkedNuggets => [ ...checkedNuggets, {
                                                        id: data.id,

                                                    }])


                                                    if (checked === false) {

                                                        const newcheckedNuggets = [ ...checkedNuggets];
                                                        const index = checkedNuggets.findIndex((checkedNuggets)=> checkedNuggets.id === data.id);
                                                        newcheckedNuggets.splice(index, 1);
                                                        setCheckedNuggets(newcheckedNuggets);


                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>




        </div>
        :
        <div className='do-not-display'></div>
        }
        </div>
        <div className='list-card'>
        {mediaSelected ?
        <div>
        <div className='hero-btns'>
        {checkedMedia.length > 0?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClickM}
        >
        Stake Selected ETHWalkers Media (you pay GAS)
        </Button>
        : <Button
            onClick={handleClick3}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Select ETHwalkers Media to Stake Below
        </Button>
        }
        </div>
        <table className='table1'>

                    <tbody>
                        {
                            redeemableMediaState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        console.log(redeemableMediaState);
                                        console.log(checkedMedia);

                                        setredeemableMediaState(
                                            redeemableMediaState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newcheckedMedia = [ ...checkedMedia];
                                                        const index = checkedMedia.findIndex((checkedMedia)=> checkedMedia.id === data.id);
                                                        newcheckedMedia.splice(index, 1);
                                                        setCheckedMedia(newcheckedMedia);



                                                    }


                                                    data.select = checked;

                                                    setCheckedMedia(checkedMedia => [ ...checkedMedia, {
                                                        id: data.id,

                                                    }])


                                                    if (checked === false) {

                                                        const newcheckedMedia = [ ...checkedMedia];
                                                        const index = checkedMedia.findIndex((checkedMedia)=> checkedMedia.id === data.id);
                                                        newcheckedMedia.splice(index, 1);
                                                        setCheckedMedia(newcheckedMedia);


                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>




        </div>
        :
        <div className='do-not-display'></div>
        }
        </div>
        </div>

        </div>
        :
        <div className='do-not-display'></div>
        }
        {stakingSelected2?
        <div className="FAQ-container">
        <p>Note: If you have a larger number of tokens they may take a minute to load. (30-60seconds)</p>
        <p>Note: The above list directly reflects the list of claimable tokens available to you per the staking contract.
        If you have staked a Nugget it should show on this list.</p>
        <p>Note: After you've clicked claim and confirmed in meta mask, you should see the confirmed contract interaction.
        If you refresh the page you'll see your NUGGS balance in the center of the screen update. (You can also go to etherscan.io
        and search your wallet address. Click on the token dropdown and you'll see your NUGGS balance under ERC-20 Tokens)</p>
        </div>
        :
        <div className='do-not-display'></div>
        }



    </div>
    :
    <div className="daddy">
        <div className='new-body'>
            <div className='hero-container-Private'>



                <div id="mint" className="flex">



                </div>
                <div className='tabs'>
                    { signedIn?


                    <div className='balance-display'>
                    <h1>
                    Unclaimed Rewards: {bigRedeemTotal} NUGGS
                    </h1>


                    </div>

                    :
                    <div className='do-not-display'></div>
                    }
                </div>
                <div className='tabs'>
                    { signedIn?
                    <div >
                    <Button
                        className='btns'
                        buttonStyle='btn--outline-round'
                        buttonSize='btn--large-2'
                        onClick={handleClickAddToken}
                    >
                    Add NUGGS token to meta mask
                    </Button>
                    </div>
                    :
                    <div className="do-not-display">

                    </div>
                    }
                </div>
                <div className='tabs'>
                    { signedIn?
                    <div>
                    {stakingSelected?
                    <Button
                        className='btns'
                        buttonStyle='btn--outline2'
                        buttonSize='btn--large'
                        onClick={handleClick3}
                    >
                    Showing Staking
                    </Button>
                    : <Button
                        onClick={handleClickStaking}
                        className='btns'
                        buttonStyle='btn--outline2'
                        buttonSize='btn--large'
                    >Show Staking
                    </Button>
                    }
                    {stakingSelected?
                    <Button
                        className='btns'
                        buttonStyle='btn--outline2'
                        buttonSize='btn--large'
                        onClick={handleClickClaiming}
                    >
                    Show Claiming
                    </Button>
                    : <Button
                        onClick={handleClick3}
                        className='btns'
                        buttonStyle='btn--outline2'
                        buttonSize='btn--large'
                    >Showing Claiming
                    </Button>
                    }
                    </div>
                    :
                    <div className='do-not-display'></div>
                    }
                </div>
                <div className='hero-btns'>
                {signedIn?
                <>
                </>
                : <Button
                    onClick={signIn}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >Click to Connect Wallet with Metamask
                </Button>
                }
                </div>


            </div>


        </div>
        {zombieSelected == false && humanSelected == false && nuggetSelected == false ?
        <div className="FAQ-container">





        <h1>Welcome to the Deepfryer:</h1>
        <h2>Your home for ETH Walkers and Nerdy Nugget staking</h2>




        </div>
        :
        <div className='do-not-display'></div>
        }

        <div className="FAQ-container">
        <h1>Claiming</h1>
        <div className='grid-container-deepfryer'>
        <div className='list-card'>
        {zombieSelected ?
        <div>
        <div className='hero-btns'>
        {checkedZombiesClaim.length > 0?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClickClaim}
        >
        Claim NUGGS for Selected Zombies (you pay GAS)
        </Button>
        : <Button
            onClick={handleClick3}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Select Zombies to Claim Below
        </Button>
        }
        </div>
        <p></p>
        <table className='table1'>

                    <tbody>
                        {
                            claimableZombieState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        //console.log(claimableZombieState);
                                        //console.log(checkedZombies);
                                        //console.log(checkedZombiesKills)
                                        //console.log(checkedZombiesSig)
                                        setclaimableZombieState(
                                            claimableZombieState.map(data => {

                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newCheckedZombiesClaim = [ ...checkedZombiesClaim];
                                                        const index = checkedZombiesClaim.findIndex((checkedZombiesClaim)=> checkedZombiesClaim.id === data.id);
                                                        newCheckedZombiesClaim.splice(index, 1);
                                                        setCheckedZombiesClaim(newCheckedZombiesClaim);

                                                        const newCheckedZombiesClaimKills = [ ...checkedZombiesClaimKills];
                                                        const index2 = checkedZombiesClaimKills.findIndex((checkedZombiesClaimKills)=> checkedZombiesClaimKills.kills === data.kills);
                                                        newCheckedZombiesClaimKills.splice(index2, 1);
                                                        setCheckedZombiesClaimKills(newCheckedZombiesClaimKills);

                                                        const newCheckedZombiesClaimSig = [ ...checkedZombiesClaimSig];
                                                        const index3 = checkedZombiesClaimSig.findIndex((checkedZombiesClaimSig)=> checkedZombiesClaimSig.sigHash === data.sigHash);
                                                        newCheckedZombiesClaimSig.splice(index3, 1);
                                                        setCheckedZombiesClaimSig(newCheckedZombiesClaimSig);

                                                    }


                                                    data.select = checked;

                                                    setCheckedZombiesClaim(checkedZombiesClaim => [ ...checkedZombiesClaim, {
                                                        id: data.id,

                                                    }])
                                                    setCheckedZombiesClaimKills(checkedZombiesClaimKills => [ ...checkedZombiesClaimKills, {
                                                        kills: data.kills,

                                                    }])
                                                    setCheckedZombiesClaimSig(checkedZombiesClaimSig => [ ...checkedZombiesClaimSig, {
                                                        sigHash: data.sigHash,

                                                    }])

                                                    if (checked === false) {

                                                        const newCheckedZombiesClaim = [ ...checkedZombiesClaim];
                                                        const index = checkedZombiesClaim.findIndex((checkedZombiesClaim)=> checkedZombiesClaim.id === data.id);
                                                        newCheckedZombiesClaim.splice(index, 1);
                                                        setCheckedZombiesClaim(newCheckedZombiesClaim);

                                                        const newCheckedZombiesClaimKills = [ ...checkedZombiesClaimKills];
                                                        const index2 = checkedZombiesClaimKills.findIndex((checkedZombiesClaimKills)=> checkedZombiesClaimKills.kills === data.kills);
                                                        newCheckedZombiesClaimKills.splice(index2, 1);
                                                        setCheckedZombiesClaimKills(newCheckedZombiesClaimKills);

                                                        const newCheckedZombiesClaimSig = [ ...checkedZombiesClaimSig];
                                                        const index3 = checkedZombiesClaimSig.findIndex((checkedZombiesClaimSig)=> checkedZombiesClaimSig.sigHash === data.sigHash);
                                                        newCheckedZombiesClaimSig.splice(index3, 1);
                                                        setCheckedZombiesClaimSig(newCheckedZombiesClaimSig);

                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>
                                <td>({d.value})</td>
                            </tr>
                        ))}
                    </tbody>
                </table>




        </div>
        :
        <div className='do-not-display'></div>
        }
        </div>
        <div className='list-card'>
        {humanSelected ?
        <div >
        <div className='hero-btns'>
        {checkedHumansClaim.length > 0?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClickHClaim}
        >
        Claim NUGGS for Selected Humans (you pay GAS)
        </Button>
        : <Button
            onClick={handleClick3}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Select Humans to Claim Below
        </Button>
        }
        </div>
        <table className='table1'>

                    <tbody>
                        {
                            claimableHumanState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        //console.log(claimableHumanState);
                                        //console.log(checkedHumans);
                                        //console.log(checkedHumansKills)
                                        //console.log(checkedHumansSig)
                                        setclaimableHumanState(
                                            claimableHumanState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newcheckedHumansClaim = [ ...checkedHumansClaim];
                                                        const index = checkedHumansClaim.findIndex((checkedHumansClaim)=> checkedHumansClaim.id === data.id);
                                                        newcheckedHumansClaim.splice(index, 1);
                                                        setCheckedHumansClaim(newcheckedHumansClaim);

                                                        const newcheckedHumansClaimKills = [ ...checkedHumansClaimKills];
                                                        const index2 = checkedHumansClaimKills.findIndex((checkedHumansClaimKills)=> checkedHumansClaimKills.kills === data.kills);
                                                        newcheckedHumansClaimKills.splice(index2, 1);
                                                        setCheckedHumansClaimKills(newcheckedHumansClaimKills);

                                                        const newcheckedHumansClaimSig = [ ...checkedHumansClaimSig];
                                                        const index3 = checkedHumansClaimSig.findIndex((checkedHumansClaimSig)=> checkedHumansClaimSig.sigHash === data.sigHash);
                                                        newcheckedHumansClaimSig.splice(index3, 1);
                                                        setCheckedHumansClaimSig(newcheckedHumansClaimSig);

                                                    }


                                                    data.select = checked;

                                                    setCheckedHumansClaim(checkedHumansClaim => [ ...checkedHumansClaim, {
                                                        id: data.id,

                                                    }])
                                                    setCheckedHumansClaimKills(checkedHumansClaimKills => [ ...checkedHumansClaimKills, {
                                                        kills: data.kills,

                                                    }])
                                                    setCheckedHumansClaimSig(checkedHumansClaimSig => [ ...checkedHumansClaimSig, {
                                                        sigHash: data.sigHash,

                                                    }])

                                                    if (checked === false) {

                                                        const newcheckedHumansClaim = [ ...checkedHumansClaim];
                                                        const index = checkedHumansClaim.findIndex((checkedHumansClaim)=> checkedHumansClaim.id === data.id);
                                                        newcheckedHumansClaim.splice(index, 1);
                                                        setCheckedHumansClaim(newcheckedHumansClaim);

                                                        const newcheckedHumansClaimKills = [ ...checkedHumansClaimKills];
                                                        const index2 = checkedHumansClaimKills.findIndex((checkedHumansClaimKills)=> checkedHumansClaimKills.kills === data.kills);
                                                        newcheckedHumansClaimKills.splice(index2, 1);
                                                        setCheckedHumansClaimKills(newcheckedHumansClaimKills);

                                                        const newcheckedHumansClaimSig = [ ...checkedHumansClaimSig];
                                                        const index3 = checkedHumansClaimSig.findIndex((checkedHumansClaimSig)=> checkedHumansClaimSig.sigHash === data.sigHash);
                                                        newcheckedHumansClaimSig.splice(index3, 1);
                                                        setCheckedHumansClaimSig(newcheckedHumansClaimSig);

                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>
                                <td>({d.value})</td>
                            </tr>
                        ))}
                    </tbody>
                </table>






        </div>
        :
        <div className='do-not-display'></div>
        }
        </div>

        <div className='list-card'>
        {nuggetSelected ?
        <div >
        <div className='hero-btns'>
        {checkedNuggetsClaim.length > 0?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClickNClaim}
        >
        Claim NUGGS for Selected Nerdy Nuggets (you pay GAS)
        </Button>
        : <Button
            onClick={handleClick3}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Select Nuggets to Claim Below
        </Button>
        }
        </div>
        <table className='table1'>

                    <tbody>
                        {
                            claimableNuggetState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        //console.log(claimableNuggetState);
                                        //console.log(checkedNuggetsClaim);

                                        setclaimableNuggetState(
                                            claimableNuggetState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newcheckedNuggetsClaim = [ ...checkedNuggetsClaim];
                                                        const index = checkedNuggetsClaim.findIndex((checkedNuggetsClaim)=> checkedNuggetsClaim.id === data.id);
                                                        newcheckedNuggetsClaim.splice(index, 1);
                                                        setCheckedNuggetsClaim(newcheckedNuggetsClaim);



                                                    }


                                                    data.select = checked;

                                                    setCheckedNuggetsClaim(checkedNuggetsClaim => [ ...checkedNuggetsClaim, {
                                                        id: data.id,

                                                    }])


                                                    if (checked === false) {

                                                        const newcheckedNuggetsClaim = [ ...checkedNuggetsClaim];
                                                        const index = checkedNuggetsClaim.findIndex((checkedNuggetsClaim)=> checkedNuggetsClaim.id === data.id);
                                                        newcheckedNuggetsClaim.splice(index, 1);
                                                        setCheckedNuggetsClaim(newcheckedNuggetsClaim);


                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>
                                <td>({d.value})</td>
                            </tr>
                        ))}
                    </tbody>
                </table>




        </div>
        :
        <div className='do-not-display'></div>
        }
        </div>
        <div className='list-card'>
        {mediaSelected ?
        <div >
        <div className='hero-btns'>
        {checkedMediaClaim.length > 0?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClickMClaim}
        >
        Claim NUGGS for Selected ETHWalkers Media(you pay GAS)
        </Button>
        : <Button
            onClick={handleClick3}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Select ETHWalkers Media to Claim Below
        </Button>
        }
        </div>
        <table className='table1'>

                    <tbody>
                        {
                            claimableMediaState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        //console.log(claimableMediaState);
                                        //console.log(CheckedMediaClaim);

                                        setclaimableMediaState(
                                            claimableMediaState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newcheckedMediaClaim = [ ...checkedMediaClaim];
                                                        const index = checkedMediaClaim.findIndex((checkedMediaClaim)=> checkedMediaClaim.id === data.id);
                                                        newcheckedMediaClaim.splice(index, 1);
                                                        setCheckedMediaClaim(newcheckedMediaClaim);



                                                    }


                                                    data.select = checked;

                                                    setCheckedMediaClaim(checkedMediaClaim => [ ...checkedMediaClaim, {
                                                        id: data.id,

                                                    }])


                                                    if (checked === false) {

                                                        const newcheckedMediaClaim = [ ...checkedMediaClaim];
                                                        const index = checkedMediaClaim.findIndex((checkedMediaClaim)=> checkedMediaClaim.id === data.id);
                                                        newcheckedMediaClaim.splice(index, 1);
                                                        setCheckedMediaClaim(newcheckedMediaClaim);


                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>
                                <td>({d.value})</td>
                            </tr>
                        ))}
                    </tbody>
                </table>




        </div>
        :
        <div className='do-not-display'></div>
        }
        </div>
        </div>
        </div>
        <div className="FAQ-container">
        <p>Note: If you have a larger number of tokens they may take a minute to load. (30-60seconds)</p>
        <p>Note: The above list directly reflects the list of claimable tokens available to you per the staking contract.
        If you have staked a Nugget it should show on this list.</p>
        <p>Note: After you've clicked claim and confirmed in meta mask, you should see the confirmed contract interaction.
        If you refresh the page you'll see your NUGGS balance in the center of the screen update. (You can also go to etherscan.io
        and search your wallet address. Click on the token dropdown and you'll see your NUGGS balance under ERC-20 Tokens)</p>
        </div>


    </div>
}
    </>




);
}
export default Mint;
